import { Controller } from '@hotwired/stimulus';
import { compact, isEmpty } from 'lodash-es';

// Connects to data-controller="checkin-form"
export default class extends Controller {
  static targets = ['input', 'error', 'border'];

  submit(e) {
    e.preventDefault();

    this.resetErrors();

    if (this.element.checkValidity()) {
      this.element.submit();
      return;
    }

    const missingInputs = Array.from(this.inputTargets).filter((inputTarget) => {
      return isEmpty(compact(inputTarget.value));
    });

    this.getMissingInputs().forEach((missingInput) => {
      this.enableErrorMessage(missingInput.dataset.id);
      this.enableErrorBorder(missingInput.dataset.id);
    });
  }

  getMissingInputs() {
    return Array.from(this.inputTargets).filter((inputTarget) => {
      return isEmpty(compact(inputTarget.value));
    });
  }

  resetErrors() {
    Array.from(this.errorTargets).forEach((errorTarget) => {
      errorTarget.classList.remove('hidden');
      errorTarget.classList.add('hidden');
    });

    Array.from(this.borderTargets).forEach((borderTarget) => {
      borderTarget.classList.remove('border-red-700');
      borderTarget.classList.add('border-gray-200');
    });
  }

  enableErrorMessage(id) {
    this.errorMessage(id).classList.remove('hidden');
  }

  enableErrorBorder(id) {
    this.errorBorder(id).classList.remove('border-gray-200');
    this.errorBorder(id).classList.add('border-red-700');
  }

  errorMessage(id) {
    return document.getElementById(`${id}-error`);
  }

  errorBorder(id) {
    return document.getElementById(`${id}-border`);
  }
}
